import { createHead, VueHeadMixin } from '@unhead/vue'

window.bootstrap = require('bootstrap/dist/js/bootstrap.min')

require('@/plugins/jquery')
require('@/plugins/jqueryFrontend')
import { createApp } from 'vue'
import App from './App.vue'
import router from '@/router'
import store from '@/store/index'
import { MOBILE_MAX_WIDTH_PX } from '@/plugins/constants'
import { loadIcons } from '@/plugins/icons'
import VueCookies from 'vue3-cookies'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const app = createApp(App),
    head = createHead()

app.use(VueCookies)
app.use(store)
app.use(router)

app.use(head)
app.mixin(VueHeadMixin)
app.component('font-awesome-icon', FontAwesomeIcon)

app.config.globalProperties.isMobile = window.matchMedia(`(max-width: ${MOBILE_MAX_WIDTH_PX}px)`).matches

window.addEventListener('resize', () => {
    app.config.globalProperties.isMobile = window.matchMedia(`(max-width: ${MOBILE_MAX_WIDTH_PX}px)`).matches
})

app.config.globalProperties.targetBlank = (url) => {
    return /http:\/\/|https:\/\/.*/.test(url)
}

loadIcons().then(() => {
    app.mount('#app')
})