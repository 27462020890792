<template>
  <header :key="mainHeaderKey" class="parent-sticky">
    <MainMenu />
    <Menu />
    <div class="info-bar font-weight-bold">
      <marquee class="main-marquee" behavior="scroll" direction="left" scrollamount="10">
        Informujemy, że w okresie od 23.12.2024 do 04.01.2025 przeprowadzamy inwentaryzację, w związku z czym w tym okresie nie będziemy wysyłać paczek. Zamówienia złożone w tym czasie będą wysyłane od 07.01.2025.
      </marquee>
    </div>
  </header>
  <div class="min-vh-100-safe">
    <router-view />
  </div>
  <ModalTerms v-if="$store.state.termModal.value === true" />
  <Footer />
  <CookiesModal 
    v-if="showCookiesModal" 
    @accept="acceptCookies"
  />
  <div
      class="fb-chat-icon cursor-pointer"
      @click="goToFb"
  />
</template>

<script>
import CookiesModal from '@/components/CookiesModal';
import MainMenu from '@/components/MainMenu'
import Footer from '@/components/Footer'
import { defineAsyncComponent } from 'vue'

const ModalTerms = defineAsyncComponent(() => import('@/components/includes/modalTerms'))
const Menu = defineAsyncComponent(() => import('@/components/Menu'))

export default {
  components: {
    CookiesModal,
    MainMenu,
    Menu,
    ModalTerms,
    Footer
  },
  data() {
    return {
      windowWidth: 0,
      windowHeight: 0,
      mainHeaderKey: 0,
    }
  },
  computed: {
    showCookiesModal() {
      return this.$store.state.cookiesModal.value
    }
  },
  methods: {
    acceptCookies() {
      this.$store.commit(
        'setCookiesModalShow', 
        false
      )
    },
    goToFb() {
      window.open('https://m.me/100063660366856', '_blank')
    },
    resizeApp() {
      const windowWidth = window.innerWidth,
          windowHeight = window.innerHeight

      const widthDifference = Math.abs(windowWidth - this.windowWidth)
      const heightDifference = Math.abs(windowHeight - this.windowHeight)

      if (widthDifference >= 25 && heightDifference >= 25) {
        this.mainHeaderKey += 1
        this.windowWidth = windowWidth
        this.windowHeight = windowHeight
      }
    },
  },
  middleware: 'cart',
  async created() {
    await this.$store.dispatch('start')

    const response = await this.$store.dispatch('getUser')

    if (response && response.length && response.address_id !== '0') {
      await this.$store.dispatch('getUserAddress', response.address_id)
    }
  },
  beforeMount() {
    this.windowWidth = window.innerWidth
    this.windowHeight = window.innerHeight
  },
  beforeUnmount() {
    window.removeEventListener('resize', this.resizeApp)
  },
  mounted() {
    let cookiesAccepted = localStorage.getItem('__hsklep_cookies_modal_accepted')

    if (cookiesAccepted) {
      cookiesAccepted = JSON.parse(cookiesAccepted)

      if (cookiesAccepted?.length && cookiesAccepted.includes('required')) {
        this.$store.commit(
          'setCookiesModalShow', 
          false
        )
      } else {
        this.$store.commit(
          'setCookiesModalShow', 
          true
        )
      }
    } else {
      this.$store.commit(
          'setCookiesModalShow', 
          true
        )
    }

    window.onscroll = function() {
      const boxShadowNavClass = 'navbar-box-shadow',
          $stickyNavbar = $('.jsStickyNavbar')

      if (window.scrollY >= 60) {
        $stickyNavbar.addClass(boxShadowNavClass)
      } else {
        $stickyNavbar.removeClass(boxShadowNavClass)
      }
    }

    window.addEventListener('resize', this.resizeApp)

    setTimeout(() => {
      const chatIcon = document.querySelector('.fb-chat-icon')
      chatIcon.classList.add('visible')
    }, 100)
  }
}
</script>

<style lang="scss">
@import '@/styles/main.scss';
</style>